import { useRef, useLayoutEffect, React } from 'react';
import '../App.css';


const TeaspoonNews = () => {
    return (
        <div className='Teaspoon'>
            <h1>Teaspoon</h1>
        </div>
    )
};

export default TeaspoonNews;