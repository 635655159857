import { useRef, useLayoutEffect, React } from 'react';
import '../App.css';


const SECNews = () => {
    return (
        <div className='SEC'>
            <h1>SEC</h1>
        </div>
    )
};

export default SECNews;